import React, { useState } from "react";
import { navigate } from "gatsby";
import API from "../../../../firebase/api";
import * as styles from "./accountSideMenu.module.scss";
import SquaresIcon from "../../../../assets/svg/account/squares.svg";
import InvoiceIcon from "../../../../assets/svg/account/invoice.svg";
import ReferralIcon from "../../../../assets/svg/account/referral.svg";
import AccountIcon from "../../../../assets/svg/account.svg";


function get_path_name(){
    if (typeof window !== 'undefined') {
        if (window.location){
            return window.location.pathname
        }
    }
    return ""
}

export default () => {

    const [currentPage, setCurrentPage] = React.useState("");
    const [isReferral, setReferral] = useState(false)

    React.useEffect(() => {
        // Check if account is registered for referrals
        (async () => {
            setReferral(await API.getReferrals());
        })();
        setCurrentPage(
            get_path_name()
        )
    }, []);

    return (
        <div className={styles.sideMenu}>
            <ul>
                <li className={currentPage.includes('templates') ? styles.active : null} onClick={() => navigate('/templates')}>
                    <SquaresIcon /> Templates
                </li>
                <hr />
                <li className={currentPage.includes('account') ? styles.active : null}
                    onClick={() => navigate('/account')}>
                    <AccountIcon />
                    <span>Account</span>
                </li>
                <li className={currentPage.includes('billing') ? styles.active : null}
                    onClick={() => navigate('/billing')}> 
                    <InvoiceIcon /> Billing
                </li>
                {isReferral ? 
                <li className={currentPage.includes('referral') ? styles.active : null}
                    onClick={() => navigate('/referral')}> 
                    <ReferralIcon /> Referrals
                </li> : null}
            </ul>
        </div>
    )
};
import Modal from '../Modal/Modal';
import React from 'react';
import * as styles from './customoptions.module.scss';


export default (props) => {
  return (
    <div>
      <Modal
        open={true}
        handleClose={props.onCancel}
      >
          <div className={styles.paper}>
            {props.icon}
            <h2 id="transition-modal-title">{props.title}</h2>
            <p id="transition-modal-description">{props.subtitle}</p>
            <div className={styles.buttons}>

            <button onClick={async () => await props.onSuccess()}>
              {props.successText}
            </button>
            <button onClick={props.onCancel}>
             {props.cancelText}
            </button>
                            
            </div>
          </div>
      </Modal>
    </div>
  );
}
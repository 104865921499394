import React from "react"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"

export default ({ component: Component, ...rest }) => {
    let user = useSelector(state => state.UserSlice.user)
    const [show, setShow] = React.useState(false)

    React.useEffect(() => {
        if(user){
            setShow(true)
        }
        else if(user === false){
            navigate("/")
        }
    }, [user])

  return show ? <Component {...rest} /> : null
}

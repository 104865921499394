import React from "react";
import { navigate } from "gatsby";
import * as Yup from 'yup'
import * as styles from './accountinfo.module.scss';

import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

import API from "../../../../firebase/api";

import FormBase from "../../../../components/Common/Form/Form"


export default () => {

    const [loader, setLoader] = React.useState(false);
    const [userData, setUserData] = React.useState(null);

    React.useEffect(() => {
        async function accountInfo() {
            let info = await API.getCurrentUser()
            setUserData(info)
        }
        accountInfo();
    }, []);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string("Must be alphanumeric").nullable(),
        last_name: Yup.string("Must be alphanumeric").nullable(),
        bill_to: Yup.string("Must be alphanumeric").nullable().max(256, "Billing info must be below 256 characters"),
      });

    async function onSubmit(values){
        let response = await API.updateCurrentUser(values)
        if(response){
          dispatch(setUser(true))
          navigate('/')
        }
        return
    }


    function renderAccountInfo(response){
        if (response==null) {
            return (
                <BeatLoader />
            )
        }
        let initialValues = {
            first_name: response.first_name,
            last_name: response.last_name,
            bill_to: response.bill_to
        }

        let fields = [
            {
                placeholder: 'John',
                label: "First Name",
                type: 'text',
                name: 'first_name'
            },
            {
                placeholder: 'Doe',
                label: "Last Name",
                type: 'text',
                name: 'last_name'
            },
            {
                placeholder: '25 Canada Square',
                label: "Billing Address",
                type: 'text',
                name: 'bill_to',
            }
        ]

        return (
            <div className={styles.form}>
                <p>
                    This information will appear on your billing invoice.
                </p>
                <FormBase
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    fields={fields}
                    submitButtonText={'Save'}
                    onSubmit={onSubmit}
                />
            </div>
        )
    }

    return renderAccountInfo(userData)
}
import React from "react";
import { navigate } from "gatsby";
import UserImage from '../../Common/UserImage/UserImage';
import Layout from '../../layout';
import * as styles from "./account.module.scss";
import { toast } from "react-toastify";
import SideMenu from "./AccountSideMenu/AccountSideMenu";
import BeatLoader from "react-spinners/BeatLoader";
import CustomOptionsModal from '../../Modals/CustomOptionsModal/CustomOptionsModal';
import API from "../../../firebase/api";
import Firebase from '../../../firebase/index';
import { useSelector } from "react-redux";
import AccountInfo from "./AccountInfo/AccountInfo"


export default () => {

  let data = useSelector((state) => state.UserSlice.userInfo);

  const [loader, setLoader] = React.useState(false);
  const [cancelModal, setCancelModal] = React.useState(false);
  const [isResendEmailLinkVisible, setResendEmailLinkVisible] = React.useState(true);


  function EmailVerified() {
    return <div><p>Your email is verified.</p></div>
  }
  
  function EmailNotVerified(data) {

    let link_content = 'Re-send Verification Link'

    return <div>
      <p>
        Your email ({data.email}) is not verified. Please check your inbox to verify your email.
      </p>
      <h4>Did not get the email?</h4>
      <p>
        Click the button below to receive a new verification link. It will expire after 1 hour.
      </p>
      {
        isResendEmailLinkVisible ? 
        <button className={styles.blackButton}
        onClick={handleSendVerificationLink}
        >{link_content}</button> : null
      }
    </div>
  }
  
  function handleSendVerificationLink() {
    setResendEmailLinkVisible(false)
    Firebase.sendEmailVerification()
  }


  async function deleteUser() {
    setLoader(true)
    setCancelModal(false)
    let response = await API.deleteUser()
    if(response){
      toast.success(`✔ Account Deleted`)
    }
    else {
      toast.error('Unable to delete account. Please contact us.')
    }
    Firebase.signOut()
    setLoader(false)
    navigate("/")
}

  function showCancelModal() {
    setCancelModal(true)
  }

  if (!data){
    navigate("/")
  }

  let button_text = loader ? (<BeatLoader color={'#ffffff'} loading={true} size={10} />) : 'Delete Account'
  return (
    <Layout>
      <div className={styles.container}>
            <div className={styles.left}>
                <SideMenu />
            </div>
            <div className={styles.right}>
            {cancelModal ?
                <CustomOptionsModal title={"Are you sure?"}
                subtitle={"You will permanently lose your account and templates."}
                successText={'Yes, delete'}
                cancelText={"No"} 
                onSuccess={deleteUser} 
                onCancel={() => setCancelModal(false)}
                /> : 
            null}
                <h1>
                    Account
                </h1>
                <AccountInfo />
                <h2>
                  Email
                </h2>
                <>
                {Object.keys(data).length !== 0 && data && !data.emailVerified ?
                <EmailNotVerified email={data.email} /> :
                <EmailVerified />
                }
                </>
                <h2>Account Status</h2>
                <button 
                className={styles.blackButton}
                onClick={showCancelModal}
                >{button_text}</button>
            </div>
      </div>
    </Layout>
  );
};
